import React from 'react';
import { TableCell, TableHead, TableRow, Table, TableSortLabel } from '@material-ui/core';
import { getLocalStorageInfo } from '../../../utils'; 
import { assetValueKey } from '../'
import config from '../../../config';
import { smbcInstance } from '../../../shared_elements/components';
const AssetHeader = ({fleetType, filter, sortInfo, createSortHandler}) => {
    return(
        <>{
            smbcInstance?
        <TableHead>
            <TableRow>
                <TableCell>
                    <div>
                        <TableSortLabel

                        active={sortInfo?.sort =='engine_type'}
                        direction={sortInfo.sort === 'engine_type' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('engine_type')}
                        className='col-align-center'
                        >
                            Type and Model
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'asset_name' ? true : false}
                        direction={sortInfo.sort === 'asset_name' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('asset_name')}
                        className='col-align-center'
                    >
                        ESN
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'lessee' ? true : false}
                        direction={sortInfo.sort === 'lessee' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('lessee')}
                        className='col-align-center'
                    >
                        Lessee
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'operator' ? true : false}
                        direction={sortInfo.sort === 'operator' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('operator')}
                        className='col-align-center'
                    >
                        Operator
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'status' ? true : false}
                        direction={sortInfo.sort === 'status' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('status')}
                        className='col-align-center'
                    >
                        Lease Status
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={sortInfo.sort === 'on_wing_msn' ? true : false}
                        direction={sortInfo.sort === 'on_wing_msn' ? sortInfo.sort_by : 'asc'}
                        onClick={() => createSortHandler('on_wing_msn')}
                        className='col-align-center'
                    >
                        Current Status
                    </TableSortLabel>
                </TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>:
        <TableHead>
            <TableRow>
                <TableCell>
                    <div style={{width:'180px'}}>
                        <TableSortLabel
                            active={sortInfo.sort === 'asset_name' ? true:false}
                            direction={sortInfo.sort === 'asset_name' ? sortInfo.sort_by :'asc'}
                            onClick={()=>createSortHandler('asset_name')}
                        >
                            Asset(s)
                        </TableSortLabel>
                    </div>
                    
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        <TableSortLabel
                            active={sortInfo.sort === 'records_count' ? true:false}
                            direction={sortInfo.sort === 'records_count' ? sortInfo.sort_by :'asc'}
                            onClick={()=>createSortHandler('records_count')}
                        >
                            No. of Files(s)
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        <TableSortLabel
                            active={sortInfo.sort === 'records_size' ? true:false}
                            direction={sortInfo.sort === 'records_size' ? sortInfo.sort_by :'asc'}
                            onClick={()=>createSortHandler('records_size')}
                        >
                            Records Size
                        </TableSortLabel>
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        File(s) In Queue
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        OCR File(s) In Queue
                    </div>
                </TableCell>
                <TableCell>
                    <div style={{width:'180px'}}>
                        File(s) to be Renamed
                    </div>
                </TableCell>
                <TableCell align='right'>Action</TableCell>
            </TableRow>
        </TableHead>
    }
        </>
    )
}
export default AssetHeader;