import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
const filter = createFilterOptions();
const AutocompleteCreatable = ({options, paramsKey, required, value, onFieldChange, optionKey, label, multiple=false, error, helperText, resetErrorKey, placeholder='', disabled=false}) => {
  return(
    <Autocomplete
      disabled={disabled}
      value={value}
      filterSelectedOptions={true}
      onChange={(e, newValue) => {
        if (typeof newValue === 'string') {
          onFieldChange(paramsKey, {[optionKey]:newValue, value:newValue})
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onFieldChange(paramsKey, {[optionKey]:newValue.inputValue, value:newValue.inputValue})
        } else {
          onFieldChange(paramsKey, newValue)
        }
      }}
      options = {options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params?.inputValue?.trim() !== '') {
          let optionsArray = options.map(optionItem => optionItem[optionKey]);
          if(!optionsArray.includes(params.inputValue.trim())){
            filtered.push({
              inputValue: params.inputValue,
              [optionKey]: `Add "${params.inputValue}"`,
            });
          }
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(option) => option[optionKey]}
      id={paramsKey}
      freeSolo={false}
      renderInput={params => <TextField required={required} error={error ? true:false} helperText={helperText} onFocus={resetErrorKey} placeholder={placeholder} {...params} label={label} margin="normal" fullWidth InputLabelProps={{shrink: true}} variant='outlined' />}
      multiple={multiple}
    />
  )
}

export default AutocompleteCreatable;
