export const assetTypeLabel = {
    1:'MSN',
    2:'ESN',
    3:'APU',
    4:'LG',
    5:'Propeller',
    6:'FAN',
    7:'HPC',
    8:'HPT',
    9:'LPT'
}
export const assetTypeKey = {
    0:'all',
    1:'aircraft',
    2:'engine',
    3:'apu',
    4:'lg',
    5:'propeller',
    6:'fan',
    7:'hpc',
    8:'hpt',
    9:'lpt'
}
export const assetValueKey = {
    'all':0,
    'aircraft':1,
    'engine':2,
    'apu':3,
    'lg':4,
    'propeller':5,
    'fan':6,
    'hpc':7,
    'hpt':8,
    'lpt':9
}
export const assetFilterOptions = {
    'aircraft_type':{
        'inputType': 'dropdown',
        'title': 'Aircraft Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'engine_type':{
        'inputType': 'dropdown',
        'title': 'Engine Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    },
    'apu_type':{
        'inputType':'dropdown',
        'title':'APU Type',
        'options':[],
        'labelKey':'name',
        'valueKey':'id',
        'multiple':true
    }
}
export const smbcFilters = {
    engine_type: {
      inputType: "dropdown",
      placeholder: "Select Engine Type",
      title: "Engine Type",
      options: [],
      labelKey: "name",
      valueKey: "id",
      multiple: true,
    },
    lessee: {
      inputType: "dropdown",
      placeholder: "Select Lessee",
      title: " Lessee",
      options: [],
      labelKey: "name",
      valueKey: "slug",
      multiple: true,
    },
    operator: {
      inputType: "dropdown",
      placeholder: "Select operator",
      title: "Operator",
      options: [],
      labelKey: "name",
      valueKey: "slug",
      multiple: true,
    },
  
    on_wing_msn: {
      inputType: "text",
      placeholder: "Select current Status",
      title: "Current Status",
    },
  
    status: {
      inputType: "dropdown",
      placeholder: "Select Status",
      title: "Status",
      options
          : [
              { label: "Archive", value: "5" },
              { label: "On Lease", value: "1" },
              { label: "Off Lease", value: "2" },
              { label: "On Ground", value: "3" },
              { label: "Written Off/ Sold / Part out", value: "4" },
            ],
      labelKey: "label",
      valueKey: "value",
      multiple: true,
    },
  }
export const leaseStatus = {
    jacLeaseStatus: [{ label: 'Prospect', value: '11' }, { label: 'In Storage', value: '10' }, { label: 'Lease Return', value: '9' }, { label: 'MOU Signed', value: '8' }, { label: 'MOU Issued', value: '7' }, { label: 'Lease Issued', value: '6' }, { label: 'Archive', value: '5' }, { label: 'Written Off/ Sold / Part out', value: '4' },
    { label: 'Off Lease', value: '2' }, { label: 'On Lease', value: '1' },],
    defaultLeaseStatus: [{ label: 'Archive', value: '5' }, { label: 'On Lease', value: '1' }, { label: 'Off Lease', value: '2' }, { label: 'On Ground', value: '3' }, { label: 'Written Off/ Sold / Part out', value: '4' },],
    airAsiaLeaseStatus: [{ label: 'Prospect', value: '11' }]
}

