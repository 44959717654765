import React, { useEffect, useState } from 'react'
import { Link, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, CircularProgress } from '@material-ui/core'
import { checkApiStatus, checkPermission } from '../../../utils'
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../..//utils/globalApiServices'
import { ArrowBack, ExpandMore, GetApp, Info } from '@material-ui/icons'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import moment from 'moment'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DownloadShareModal, ShareContentModal } from '../components'
import { useSnackbar } from 'notistack'
import axios from 'axios'
import { STableLoader } from '../../../shared_elements/loaders'
import { EmptyCollection } from '../../../shared_elements'
const CertificatesHd = () => {
    return (
        <TableHead style={{ position: "sticky", top: '0' }}>
            <TableRow>
                <TableCell width='700px'>File Name</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell align='right'>Action(s)</TableCell>
            </TableRow>
        </TableHead>
    )
}
const CertificateListing = ({ item, onDelete }) => {
    return (
        <TableRow style={{ backgroundColor: item?.file_exists ? '' : '#ffeeee' }}>
            <TableCell>
                <div style={{ color: item?.file_exists ? '' : 'red' }}>
                    {item?.file_name || '--'}
                    <div style={{ fontSize: '11px', color: item?.file_exists ? '' : 'red' }}>
                        {item?.folder_path || '--'}
                    </div>
                    {item?.file_exists ? null :
                        <div className='info-area-mini-pack'>
                            This File or it's Folder has been modified in the Dataroom.
                        </div>
                    }
                </div>
            </TableCell>
            <TableCell>
                <div style={{ color: item?.file_exists ? '' : 'red' }}>
                    {item?.created_by?.name || '--'}
                    <div style={{ fontSize: '11px' }}>
                        {item?.created_at ? moment(item?.created_at).format('DD-MM-YYYY, hh:mm a') : null}
                    </div>
                </div>
            </TableCell>
            <TableCell>
                <ul className='list-inline' style={{ float: 'right' }}>
                    <li className='list-inline-item' style={{ cursor: 'pointer', marginRight: '5px' }}>
                        <Tooltip arrow title='View File' placement='top'>
                            <VisibilityIcon color='primary' fontSize='small'
                                onClick={() => window.open(`/records/preview/${item.file_uuid}`, '_blank')}
                            />
                        </Tooltip>
                    </li>
                </ul>
            </TableCell>
        </TableRow>
    )
}
const UploadCoverPhoto = ({ savePhoto, toggleModalFn, miniPackDetails }) => {
    const [isLoading, setLoading] = useState(null)
    const downLoadFileAxios = (fileUrl = 'url', fileName = 'file.jpg') => {
        setLoading(true)
        axios.get(fileUrl, { responseType: 'arraybuffer', })
            .then(response => {
                setLoading(false)
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click()
            })
    }
    const downloadImage = () => {
        downLoadFileAxios(miniPackDetails.cover_photo, miniPackDetails?.cover_photo_name ? 'Cover Photo ' + miniPackDetails?.cover_photo_name : 'Cover Photo.jpeg')
    }
    return (
        <Dialog
            open={true}
            onClose={toggleModalFn}
            className='console-management-modal'
            maxWidth="lg"
        >
            <DialogTitle>
                Cover Photo
            </DialogTitle>
            <DialogContent dividers={true} >
                {
                    miniPackDetails?.cover_photo ?
                        <div className="cover-pic-action-box" style={{ width: "450px", height: "240px" }}>
                            <img width="200" style={{ margin: '10px auto', display: 'block' }} src={miniPackDetails.cover_photo} alt="" />
                        </div>
                        : null
                }
            </DialogContent>
            <DialogActions>
                <Button style={{ width: '110px' }} color="primary" variant="outlined" size='small' onClick={downloadImage}>
                                        {isLoading ? <CircularProgress size={24} /> : 'Download'}
                </Button>
                <Button variant='outlined' color='primary' size='small' onClick={toggleModalFn}>
                    Okay
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default function SharedMiniPackList(params) {
    const [sectionList, setSectionList] = useState([])
    const [downloadShareData, setDownloadShareData] = useState({ email: '' })
    const [uploadCVPhoto, setUploadCVPhoto] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState([])
    useEffect(() => {
        getSectionsFn()
    }, [])
    const getSectionsFn = (query) => {
        setLoading(true);
        globalGetService(`records/v2/view-shared-records/${params?.match?.params?.key}/`, query)
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    setSectionList(response.data.data)
                }
            })
    }
    const downloadShareDataFn = (data) => {
        setLoading(true);
        let url = `records/v2/share-record/minipack/${params?.match?.params?.key}/zip-download-user/`
        globalPostService(url, { user_email: [data] })
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setDownloadShareData({ email: '' })
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
    }
    return (
        <section className="share-content-section">
        {
            isLoading ? <STableLoader count={7} /> :
            <>
            <div style={{ height: '70px', width: '100%' }}></div>
            <div className="share-header">
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={12} sm={12} md={4}>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <img width="32" src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/sparta_logo.png" alt="Logo" />
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                        <ul className="list-inline lessor-info">
                            <li className="list-inline-item">
                                {sectionList?.asset && sectionList?.asset?.lessor_logo ?
                                    <img src={sectionList?.asset?.lessor_logo} alt={sectionList?.asset.lessor} /> : null
                                }
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
            <Paper className="navigation-box" style={{height:'50px'}}>
                <h4 style={{display:'flex',justifyContent:"space-between",alignItems:'center'}}>
                    {sectionList?.asset && sectionList?.asset.msn ? sectionList?.asset.msn : ' -'}
                    <span>
                    <Button variant='contained' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={() => { setUploadCVPhoto({ modal: true }) }}>View Cover Photo </Button>
                    <Button variant='outlined' color='primary' style={{ margin: '10px 5px 5px 5px' }} size='small' onClick={() => setDownloadShareData({ modal: true, email: '' })}><CloudDownloadIcon color="primary" style={{marginRight:'3px'}} /> Download Mini-pack </Button>
                    </span>
                </h4>
            </Paper>
            { sectionList?.list?.length ?
                    sectionList?.list?.map((section, index) =>
                        <Grid style={{ paddingBottom: sectionList?.list?.length - 1 == index ? '100px' : '0px', }} item xs='12'>
                            <Paper style={{ borderTop: '1px solid #bababa', borderRadius: "0px", marginTop: "20px" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs='3'>
                                        <div className='table-hd' style={{ margin: '9px' }}>
                                            {section?.name || '--'}
                                        </div>
                                    </Grid>
                                </Grid>
                                <Paper style={{ borderRadius: '0px' }}>
                                    <Table className='mui-table-format'>
                                        <CertificatesHd />
                                        <TableBody>
                                            {
                                                section?.files?.length ? section?.files.map(item => (
                                                    <CertificateListing
                                                        item={item}
                                                    />
                                                )) :
                                                    null
                                            }
                                        </TableBody>
                                    </Table>
                                    {section?.files?.length ? null :
                                        <Paper style={{ padding: '20px', textAlign: "center" }}>No Records Found </Paper>}
                                </Paper>
                            </Paper>
                        </Grid>)
                    : 
                    !isLoading ? <EmptyCollection title="No Records Found" /> : null
            }
            <DownloadShareModal
                downloadShareData={downloadShareData}
                onFieldChange={(value) => setDownloadShareData(prevState => ({ ...prevState, email: value }))}
                toggleModalFn={() => setDownloadShareData({})}
                onDownloadShareData={() => downloadShareDataFn(downloadShareData.email)}
            />
            {
                uploadCVPhoto?.modal ?
                    <UploadCoverPhoto
                        miniPackDetails={sectionList?.config_details}
                        toggleModalFn={() => setUploadCVPhoto(null)}
                    />
                    : null
                }
                </>
    }
        </section>
    )
}
