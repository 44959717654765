import appConfig from "../../config";
import {getLocalStorageInfo} from '../../utils';
export const leaseStatus = {
                  jacLeaseStatus: [{label: 'Prospect', value:'11'},{label: 'In Storage', value: '10'}, {label: 'Lease Return', value: '9'}, {label: 'MOU Signed', value: '8'},{label: 'MOU Issued', value: '7'},{label: 'Lease Issued', value: '6'},{label: 'Archive', value: '5'},{label: 'Written Off/ Sold / Part out', value: '4'},
                                     {label: 'Off Lease', value: '2'},{label: 'On Lease', value: '1'},],
                  defaultLeaseStatus:[{label: 'Archive', value:'5'},{label: 'On Lease', value: '1'}, {label: 'Off Lease', value: '2'}, {label: 'On Ground', value: '3'}, {label: 'Written Off/ Sold / Part out', value: '4'},],
                  }
export const castleLakeInstance = ['api','uat'].includes(appConfig.env.key) ? [479,472].includes(getLocalStorageInfo()?.defaultLessor?.id) : getLocalStorageInfo()?.defaultLessor?.id === 537 ;
export const halcyonInstance = ['api','uat'].includes(appConfig.env.key) ? [483,484].includes(getLocalStorageInfo()?.defaultLessor?.id) :  [539,540].includes(getLocalStorageInfo()?.defaultLessor?.id);
export const lessorCheckInstance =  [406].includes(getLocalStorageInfo()?.defaultLessor?.id) ;
export const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (appConfig.env.key === "api" ? 465 : 532 );
