import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link, withRouter } from 'react-router-dom';
import { Grid, Badge, TextField, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ShareIcon from '@material-ui/icons/Share';
import MoreActionManu from './MoreActionManu';
import DriveToolManu from './DriveToolManu';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { PageLoader } from '../../../shared_elements';
import { getLocalStorageInfo, checkApiStatus, checkPermission } from '../../../utils';
import { revAssetTypeValues, assetTypeValues } from '../../../constants';
import { isMobile } from 'react-device-detect';
import { getRecordsDetailApi, exportAssetInventoryApi } from '../apiServices';
import bucketIcon from '../../../assets/images/la_bitbucket.svg'
import techSpecsIcon from '../../../assets/images/tech_specs_icon.svg'
import config from '../../../config';
import { trackActivity } from '../../../utils/mixpanel';
class DataRoomHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false
    }
    this.getRecordsDetailApi = getRecordsDetailApi.bind(this);
    this.exportAssetInventoryApi = exportAssetInventoryApi.bind(this);
  }
  changeAssetSwitcher = (asset) => {
    this.getRecordsDetailApi(asset)
      .then(response => {
        if (checkApiStatus(response)) {
          const avolonInstance = getLocalStorageInfo()?.defaultLessor?.id === 470
          if(avolonInstance){
            let asset = response.data.data;
            let url = `/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/data-room/${asset?.record_folders[0]?.key_word}`
            if(asset?.record_folders && asset?.record_folders[0]){
              this.props.history.push(`${url}/${asset?.record_folders[0]?.uuid}`)
              setTimeout(() => {window.location.reload(false)}, 800) 
            }else{
              this.props.enqueueSnackbar('Records module not available for this Asset', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
            }
          }else{
            let asset = response.data.data;
            let url = `/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/data-room/${this.props.match.params.type}`
            let uuid = '', parentUuid ='';
            if(asset?.record_folders?.technical_records?.uuid){
              parentUuid = asset.record_folders.technical_records.uuid;
            }
            switch (this.props.match.params.type) {
              case 'technical':
                if(asset?.record_folders?.technical_records?.uuid){
                  uuid = asset.record_folders.technical_records.uuid;
                }
                break;
              case 'inspection':
                if(asset?.record_folders?.inspection_report?.uuid && asset?.record_folders?.inspection_report?.disable_inspections === false){
                  uuid = asset.record_folders.inspection_records.uuid;
                }
                break;
              case 'contract':
                if(asset?.record_folders?.contract_records?.uuid && asset?.record_folders?.contract_records?.disable_contracts === false){
                  uuid = asset.record_folders.contract_records.uuid;
                }
                break;
              case 'maintanence':
                if(asset?.record_folders?.maintanence_records?.uuid && asset?.record_folders?.maintanence_records?.disable_maintenance === false){
                  uuid = asset.record_folders.maintanence_records.uuid
                }
                break;
              case 'discrepancy':
                if(asset?.record_folders?.discrepancy_records?.uuid && asset?.record_folders?.discrepancy_records?.disable_discripency === false){
                  uuid = asset.record_folders.discrepancy_records.uuid;
                }
                break;
              default:
            }
            if(uuid) {
              if(asset.archive_status){
                this.props.history.push(`${url}/${uuid}?archived-assets`)
              }else { 
                this.props.history.push(url + '/' + uuid);
              }
              setTimeout(() => {window.location.reload(false)}, 800) 
            } else {
              if(parentUuid){
                this.props.history.push(`/${assetTypeValues[asset.asset_type].urlType}/${asset.slug}/data-room/technical/${parentUuid}`);
                setTimeout(() => {window.location.reload(false)}, 800) 
              }else{
                this.props.enqueueSnackbar('Records module not available for this Asset', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
              }
            }
          }
        }
      });
  }
  render() {
    const { pageLoader } = this.state;
    const { allAssets, resourcesKits, downloadResourceKit, history, match, recordsRepos } = this.props;
    let selectesAsset = allAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
    let newAllAssets = allAssets
    if(!selectesAsset && getLocalStorageInfo()?.defaultLessor?.id == 242){
      newAllAssets = [...allAssets, {unique_name:`${assetTypeValues[this.props?.recordsRepos?.asset_type]?.label} ${this.props?.recordsRepos?.asset_name}`, id:this.props?.recordsRepos?.id, slug: this.props?.recordsRepos?.slug , asset_type:this.props?.recordsRepos?.asset_type, asset_name:this.props?.recordsRepos?.asset_name  }]
      selectesAsset = newAllAssets.find(asset => asset.slug === this.props.match.params.assetSlug && asset.asset_type === revAssetTypeValues[this.props.match.params.assetType].assetType)
   }
   selectesAsset = selectesAsset ? selectesAsset :{asset: recordsRepos?.asset_name, unique_name:`${assetTypeValues[recordsRepos?.asset_type]?.label || ''} ${recordsRepos?.asset_name || ''}`}
    const avolonInstance = getLocalStorageInfo()?.defaultLessor?.id === 470
    const smbcInstance =  getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532 );
    return (
      <Fragment>
        <div className="data-room-head">
          <Grid container alignItems="center" spacing={isMobile ? 2 : 3}>
            <Grid item xs={6} sm={6} md={6}>
              <ul className="list-inline flex-centered asset-switcher">
                <li className="list-inline-item">
                  <Autocomplete
                    options={newAllAssets}
                    getOptionLabel={option => option.unique_name}
                    value={selectesAsset ? selectesAsset: null}
                    onChange={(e, value) => value ? this.changeAssetSwitcher(value) : e.preventDefault()}
                    renderInput={params => <TextField {...params} margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
                  />
                </li>
                {isMobile ? null :(
                  smbcInstance ? null :<li className="list-inline-item link-export">
                    <a href={`${config.domain.subDomian}technical-specification/${this.props.match.params.assetType}/${this.props.match.params.assetSlug}`} target="_blank" className="flex-centered"><img src={techSpecsIcon} style={{ marginRight: '4px' }} alt="" /> Technical Specification</a>
                  </li>)
                }
                {isMobile ? null :
                  checkPermission('reports','inventory','EXP') ?
                    <li className="list-inline-item link-export">
                      <Link className="flex-centered" onClick={() => this.exportAssetInventoryApi(this.props)}><SaveAltIcon fontSize="small" /> Inventory</Link>
                    </li> : null
                }
              </ul>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <ul className="list-inline more-action-cta flex-centered">
                {
                  isMobile ? null :
                    <li className="list-inline-item drivetool-menu">
                      <DriveToolManu />
                    </li>
                }
                
                {
                  avolonInstance && ['technical','pre_purchase_event','mid_term_inspection','redelivery','checks'].includes(this.props.match.params.type) ?
                  <li className="list-inline-item more-action-menu">
                  <MoreActionManu
                    resourcesKits={resourcesKits}
                    downloadResourceKit={downloadResourceKit}
                  />
                </li> 
                  :
                  this.props.match.params.type === 'technical' && (checkPermission('records','data_room','DN') || checkPermission('records','category','R') || checkPermission('records','mini-pack','R')) ?
                  <li className="list-inline-item more-action-menu">
                    <MoreActionManu
                      resourcesKits={resourcesKits}
                      downloadResourceKit={downloadResourceKit}
                    />
                  </li> 
                  : null
                }
              </ul>
            </Grid>
          </Grid>
        </div>
        {pageLoader ? <PageLoader /> : null}
        <div style={isMobile ? { height: 'auto' } : { height: '42px' }}></div>
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  recordsRepos: state.shareReducer.recordsRepos,
});
export default withSnackbar(withRouter(connect(mapStateToProps, null)(DataRoomHeader)));
